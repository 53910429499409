// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-discovery-index-tsx": () => import("./../../src/pages/discovery/index.tsx" /* webpackChunkName: "component---src-pages-discovery-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-ncube-index-tsx": () => import("./../../src/pages/ncube/index.tsx" /* webpackChunkName: "component---src-pages-ncube-index-tsx" */),
  "component---src-pages-sugarcube-index-tsx": () => import("./../../src/pages/sugarcube/index.tsx" /* webpackChunkName: "component---src-pages-sugarcube-index-tsx" */),
  "component---src-pages-sugarcube-plugins-instruments-tsx": () => import("./../../src/pages/sugarcube/plugins-instruments.tsx" /* webpackChunkName: "component---src-pages-sugarcube-plugins-instruments-tsx" */),
  "component---src-templates-template-simple-tsx": () => import("./../../src/templates/template-simple.tsx" /* webpackChunkName: "component---src-templates-template-simple-tsx" */),
  "component---src-templates-template-tsx": () => import("./../../src/templates/template.tsx" /* webpackChunkName: "component---src-templates-template-tsx" */)
}

